




























































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { organisationModule } from "@/store/modules/OrganisationModule";
import Utils from "@/common/utils/utils";
import readXlsxFile from "read-excel-file";

@Component
export default class ManualEmissionsImport extends Vue {
  private batch = {
    emissionTypeId: null,
    description: null,
    emissions: [],
    fromYear: new Date().getFullYear(),
    fromMonth: 1,
    toMonth: 1,
  };

  private format: number = 1;
  private errors: string = null;
  private dataRows = [];

  private get formatDescription(): string {
    if (this.format == 0)
      return "Excel-fil med följande kolumner: Kostnadsställe, Co2, Kostnad, Kvantitet";
    else if (this.format == 1)
      return "Excel-fil med följande kolumner: Datum, Kostnadsställe, Co2, Kostnad, Kvantitet";
    else return "Okänt format.";
  }

  private get isEmissionTypeSelected(): boolean {
    return this.batch.emissionTypeId;
  }

  private get isFileSelected(): boolean {
    return this.isEmissionTypeSelected && this.dataRows.length > 0;
  }

  private get organisation(): OrganisationDetails {
    return organisationModule.organisation;
  }

  private get numberOfMonths(): number {
    return this.batch.toMonth + 1 - this.batch.fromMonth;
  }

  private async emissionTypeIdChanged() {
    var emissionType = this.organisation.emissionTypes.find(
      (x) => x.id === this.batch.emissionTypeId
    );

    this.batch.description =
      emissionType.name + " " + new Date().toLocaleDateString("sv-SE");

    this.distributeEmissions();
  }

  private fromYearChanged() {
    this.distributeEmissions();
  }

  private fromMonthChanged() {
    this.distributeEmissions();
  }

  private toMonthChanged() {
    this.distributeEmissions();
  }

  private numberOfMonthsChanged() {
    this.distributeEmissions();
  }

  private distributeEmissions() {
    if (!this.batch.emissionTypeId) return;

    var startDate = new Date(this.batch.fromYear, this.batch.fromMonth - 1, 1);

    this.batch.emissions = this.dataRows.flatMap((item) =>
      Array(this.numberOfMonths)
        .fill(null)
        .map((x, index) => {
          var date = new Date(startDate);
          date.setMonth(date.getMonth() + index);

          return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            orgUnitId: item.orgUnitId,
            quantity: Math.round(item.quantity / this.numberOfMonths),
            cost: Math.round(item.cost / this.numberOfMonths),
            co2: Utils.round(item.co2 / this.numberOfMonths),
          };
        })
    );
  }

  private getOrgUnitCostCenterAndNameById(id: string) {
    const orgUnit = this.getOrgUnitById(id);
    const text = orgUnit.name;
    return text.trim();
  }

  private getOrgUnitById(id: string) {
    return this.organisation.orgUnits.find((x) => x.id == id);
  }

  private getOrgUnitByCostCenter(costCenter: string) {
    for (var i = 0; i < this.organisation.orgUnits.length; i++) {
      let orgUnit = this.organisation.orgUnits[i];
      console.log("test", costCenter, orgUnit);
      if (orgUnit.costCenters && orgUnit.costCenters.length > 0) {
        for (var n = 0; n < orgUnit.costCenters.length; n++) {
          if (orgUnit.costCenters[n] == costCenter) {
            return orgUnit;
          }
        }
      }
    }

    return null;
  }

  private excelFileChanged(event) {
    this.errors = null;

    readXlsxFile(event.target.files[0]).then((rows) => {
      if (this.validateExcelData(rows)) {
        this.parseExcelData(rows);

        if (this.format == 0) {
          this.distributeEmissions();
        } else {
          this.batch.emissions = this.dataRows;
        }
      }
    });
  }

  private validateExcelData(rows: any[]): boolean {
    if (rows.length == 0) {
      this.errors = "Filen innehåller inga rader.";
      return false;
    }

    if (this.format == 0) {
      for (var i = 0; i < rows.length; i++) {
        if (rows[i].length != 4) {
          this.errors = "Rad " + (i + 1) + " innehåller inte fyra kolumner.";
          return false;
        }

        for (var columnIndex = 0; columnIndex < rows[i].length; columnIndex++) {
          if (rows[i][columnIndex] === "" || rows[i][columnIndex] === null) {
            this.errors =
              "Rad " +
              (i + 1) +
              ", kolumn " +
              (columnIndex + 1) +
              " är blankt.";

            return false;
          }
        }
      }

      if (rows[0][0] != "Kostnadsställe") {
        this.errors = "Kolumn 1 har inte rubriken Kostnadsställe";
        return false;
      }

      if (rows[0][1] != "Co2") {
        this.errors = "Kolumn 2 har inte rubriken Co2";
        return false;
      }

      if (rows[0][2] != "Kostnad") {
        this.errors = "Kolumn 3 har inte rubriken Kostnad";
        return false;
      }

      if (rows[0][3] != "Kvantitet") {
        this.errors = "Kolumn 4 har inte rubriken Kvantitet";
        return false;
      }
    } else if (this.format == 1) {
      for (var i = 0; i < rows.length; i++) {
        if (rows[i].length != 5) {
          this.errors = "Rad " + (i + 1) + " innehåller inte fem kolumner.";
          return false;
        }

        for (var columnIndex = 0; columnIndex < rows[i].length; columnIndex++) {
          if (rows[i][columnIndex] === "" || rows[i][columnIndex] === null) {
            this.errors =
              "Rad " +
              (i + 1) +
              ", kolumn " +
              (columnIndex + 1) +
              " är blankt.";
            console.log(rows[i]);
            return false;
          }
        }
      }

      if (rows[0][0] != "Datum") {
        this.errors = "Kolumn 1 har inte rubriken Datum";
        return false;
      }

      if (rows[0][1] != "Kostnadsställe") {
        this.errors = "Kolumn 1 har inte rubriken Kostnadsställe";
        console.log(rows[0]);
        return false;
      }

      if (rows[0][2] != "Co2") {
        this.errors = "Kolumn 2 har inte rubriken Co2";
        return false;
      }

      if (rows[0][3] != "Kostnad") {
        this.errors = "Kolumn 3 har inte rubriken Kostnad";
        return false;
      }

      if (rows[0][4] != "Kvantitet") {
        this.errors = "Kolumn 4 har inte rubriken Kvantitet";
        return false;
      }
    }

    return true;
  }

  private parseExcelData(rows) {
    this.dataRows = [];
    const rootOrgUnit = this.organisation.orgUnits.find(
      (x) => x.parentId == null
    );

    for (var i = 1; i < rows.length; i++) {
      if (this.format == 0) {
        let costCenter = String(rows[i][0]);
        let co2 = rows[i][1];
        let cost = rows[i][2];
        let quantity = rows[i][3];

        let orgUnit = this.getOrgUnitByCostCenter(costCenter);
        if (!orgUnit) orgUnit = rootOrgUnit;

        this.dataRows.push({
          orgUnitId: orgUnit.id,
          quantity: quantity,
          cost: cost,
          co2: co2,
        });
      } else if (this.format == 1) {
        let date = new Date(String(rows[i][0]));
        let costCenter = String(rows[i][1]);
        let co2 = rows[i][2];
        let cost = rows[i][3];
        let quantity = rows[i][4];

        let orgUnit = this.getOrgUnitByCostCenter(costCenter);
        if (!orgUnit) orgUnit = rootOrgUnit;

        this.dataRows.push({
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          orgUnitId: orgUnit.id,
          quantity: quantity,
          cost: cost,
          co2: co2,
        });
      }
    }

    console.log("data", this.dataRows);
  }

  private async importEmissions() {
    await adminService.importManualEmissions(this.organisation.id, this.batch);
    this.clearBatch();
  }

  private async clearBatch() {
    this.batch = {
      emissionTypeId: null,
      description: null,
      emissions: [],
      fromYear: new Date().getFullYear(),
      fromMonth: 1,
      toMonth: 1,
    };
  }

  private activated() {
    console.log("activated");
    this.clearBatch();
  }
}
